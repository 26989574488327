/* Import variables */
@import '../styles/variables.css';

/* Light gray background for the entire dashboard */
.dashboard-container {
    background-color: var(--background-light);
    min-height: 100vh;
    padding: var(--spacing-md);
}

/* Top section with sliders and list/map container */
.top-container {
    display: flex;
    gap: var(--spacing-md);
    margin-bottom: var(--spacing-lg);
}

/* Slider section styling */
.slider-container {
    background-color: var(--background-white);
    border-radius: var(--border-radius-md);
    width: 25%;
    margin: var(--spacing-sm);
    box-shadow: var(--shadow-md);
    display: flex;
    flex-direction: column;
    position: relative;
}

/* Header section with title and tooltip */
.slider-header {
    position: relative;
    padding-bottom: var(--spacing-md);
    margin-bottom: 0; /* Remove bottom margin */
    border-bottom: none; /* Remove the bottom border */
}

/* Sliders container */
.sliders-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-md) 0;
}

/* Individual slider group styling */
.slider-group {
    display: flex;
    align-items: center;
    margin: var(--spacing-sm) 0;
    background: none; /* Remove background */
    padding: 0; /* Remove padding */
}

.slider-group .MuiSlider-root {
    height: 8px; /* Make sliders slightly bigger */
}

.slider-group .MuiSlider-thumb {
    width: 20px;
    height: 20px;
}

/* Individual slider styling */
.slider {
    width: 100%;
    margin: 5px 0;
}

/* Container for dynamic list and map */
.list-map-container {
    background-color: var(--background-white);
    padding: var(--spacing-lg);
    width: 75%;
    display: flex;
    gap: var(--spacing-lg);
    margin: var(--spacing-sm);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    align-items: stretch; /* Make children stretch to match heights */
}

/* Dynamic list styling */
.dynamic-list {
    flex: 1;
    width: 50%;
    display: flex;
    flex-direction: column;
}

/* Scrollable list of POIs */
.poi-list {
    max-height: 270px; /* Height of approximately 2 POI cards */
    overflow-y: auto; /* Enable vertical scrolling */
}

/* Section header for POIs */
.section-header {
    position: sticky;
    top: 0;
    background-color: var(--background-white);
    z-index: 1;
    padding-bottom: var(--spacing-md);
}

/* Map container styling */
.map-container {
    flex: 1;
    width: 50%;
    height: auto; /* Allow height to be determined by content */
    min-height: 100%; /* Ensure it's at least as tall as its container */
    display: flex;
    flex-direction: column;
}

/* Bottom list section styling */
.bottom-list-container {
    background-color: #ffffff; /* White background */
    padding: 15px;
    border-radius: 4px; /* Optional: slightly rounded edges */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slider-container {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    width: 20%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style for individual slider */
.slider {
    width: 100%;
    margin: 5px 0;
}

/* Style for the "Add Interest Point" button */
.add-button {
    margin-top: 15px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.add-button:hover {
    background-color: #0056b3;
}
.button {
  margin-top: 0px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.hover {
  background-color: #0056b3;
}
/* Grouped sliders container */
.slider-group {
    display: flex;
    align-items: center;
    margin: var(--spacing-sm) 0;
}

.slider-group .MuiSlider-root {
    height: 8px; /* Make sliders slightly bigger */
}

.slider-group .MuiSlider-thumb {
    width: 20px;
    height: 20px;
}

.slider-group {
    margin-top: 15px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    position: relative;
}

/* Editable group name input */
.group-name-input {
    position: absolute;
    top: -10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 14px;
    color: #333;
    outline: none;
    border-bottom: 1px solid #ccc;
}

/* Styling for sliders in a group */
.grouped-slider {
    width: 100%;
    margin: 5px 0;
}
.point-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    background-color: var(--background-white);  /* Changed from var(--background-light) to white */
    border-radius: var(--border-radius-sm);
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-sm);
    box-shadow: var(--shadow-sm);  /* Optional: adds subtle shadow for depth */
}
  
  .address {
    flex: 2;
    font-weight: bold;
  }
  
  .importance-indicators {
    display: flex;
    gap: 10px;
  }
  
  .add-point-button {
    width: 100%;  /* Match width of point-entry cards */
    background-color: var(--background-white);
    color: var(--secondary-color);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--secondary-color);
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: var(--spacing-md);
  }
  
  .add-point-button:hover {
    background-color: var(--secondary-color);
    color: var(--background-white);
  }
  
  .importance-circle {
    position: relative;
    width: 60px; /* Width of the outer circle */
    height: 60px; /* Height of the outer circle */
    border-radius: 50%; /* Creates a full circle */
    background-color: transparent; /* Make background transparent */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensures that overflowing content is hidden */
  }
  
  .importance-segment {
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: polygon(50% 50%, 100% 0%, 100% 100%);
    background-color: transparent; /* Transparent background */
    border-radius: 50%; /* Make it circular */
  }
  
  .importance-ring {
    position: absolute;
    width: 60px; /* Width of the outer ring */
    height: 60px; /* Height of the outer ring */
    border: 5px solid transparent; /* Border for outer ring */
    border-radius: 50%; /* Make it circular */
    box-sizing: border-box; /* Ensures borders are included in the width/height */
  }
  
  .importance-ring[data-importance="1"] {
    border-top-color: #4caf50; /* Color for filled segment */
  }
  
  .importance-ring[data-importance="2"] {
    border-top-color: #4caf50;
    border-right-color: #4caf50; /* 2 segments filled */
  }
  
  .importance-ring[data-importance="3"] {
    border-top-color: #4caf50;
    border-right-color: #4caf50;
    border-bottom-color: #4caf50; /* 3 segments filled */
  }
  
  .importance-ring[data-importance="4"] {
    border-top-color: #4caf50;
    border-right-color: #4caf50;
    border-bottom-color: #4caf50;
    border-left-color: #4caf50; /* 4 segments filled */
  }
  
  .importance-ring[data-importance="5"] {
    border-top-color: #4caf50;
    border-right-color: #4caf50;
    border-bottom-color: #4caf50;
    border-left-color: #4caf50; /* 5 segments filled */
  }
  
  .importance-value {
    position: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

.point-info {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.point-name {
    font-size: 16px; /* Main name font size */
    font-weight: bold;
}

.point-address {
    font-size: 14px; /* Smaller font size for address */
    color: lightgrey; /* Light grey color */
}
.modal-box {
    position: relative;
    padding: 20px;
    background-color: var(--background-white);
    width: 400px; /* Adjust width as needed */
    margin: 10vh auto;
    border-radius: 8px;
    outline: none;
    border-radius: var(--border-radius-md);
    padding: var(--spacing-xl);
    width: 90%;
    max-width: 500px;
    margin: 10vh auto;
  }
.delete-button {
    background-color: #e53935;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    position: absolute; /* Positioning within the modal */
    bottom: 20px;
    right: 20px;
}

.delete-button:hover {
background-color: #d32f2f; /* Darker red on hover */
}

.delete-button:active {
background-color: #b71c1c; /* Even darker red on active */
}

.delete-button:focus {
outline: none;
box-shadow: 0 0 0 3px rgba(229, 57, 53, 0.5); /* Light red shadow for focus */
}

.segment {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center; /* Rotate around the center */
    transform: translate(-50%, -50%) rotate(0deg); /* Start with no rotation */
  }
  
  .segment img {
    width: 90%; /* Adjust to fit container */
    height: 90%;
  }

  .flex-wrapper {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .single-chart {
    width: 83px;
    justify-content: space-around ;
  }
  
  .circular-chart {
    display: block;
    max-width: 100%;
  }
  
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }
  
  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  
  .percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }

  
  /* Wrapper to control overflow and responsive scaling */
.table-container {
    width: 100%;
    overflow-x: auto; /* Adds horizontal scrolling for small screens */
    background-color: var(--background-white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    margin-top: var(--spacing-md);
}

/* Main table styling */
.rounded-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 14px;
    border-radius: var(--border-radius-md);
    overflow: hidden;
    box-shadow: var(--shadow-sm);
}

/* Apply consistent column width */
.rounded-table th, .rounded-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    padding: var(--spacing-md);
    border-bottom: 1px solid var(--border-color);
}

/* Update table cell alignment */
.rounded-table th, 
.rounded-table td {
    text-align: center;
    vertical-align: middle;
    padding: var(--spacing-md);
    border-bottom: 1px solid var(--border-color);
}

/* Header rows style */
.rounded-table thead tr:first-child th {
    background-color: #ffffff;
    text-align: center;
    vertical-align: bottom;
    padding-top: 8px;
    padding-bottom: 4px;
    padding: var(--spacing-md);
}

/* Smaller font for addresses */
.rounded-table thead tr:first-child th small {
    display: block;
    color: #ffffff;
    font-size: 12px;
}

/* Alternating row colors */
.rounded-table tbody tr:nth-child(odd) {
    background-color: #ebebeb;
}

.rounded-table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

/* Rounded corners */
.rounded-table {
    border-radius: 8px;
    overflow: hidden;
}

/* Updated table styles */
.rounded-table th {
    position: sticky;
    top: 0;
    background-color: var(--background-white);
    z-index: 10;
    padding: var(--spacing-sm);
    border-bottom: 1px solid var(--border-color);
    color: var(--text-secondary);
    font-weight: 500;
    font-size: 0.9rem;
}

/* Basic info headers (spanning two rows) */
.basic-info-header {
    border-bottom: 2px solid var(--border-color) !important;
    vertical-align: bottom;
    padding-bottom: var(--spacing-md) !important;
    background-color: #f5f5f5 !important;  /* Light grey background */
    color: var(--text-secondary);
    font-weight: 500;
    text-align: left;
    padding: var(--spacing-md) !important;
    border-bottom: 1px solid var(--border-color);
}

/* POI group header */
.poi-header {
    background-color: var(--background-white) !important;
    text-align: center;
    font-weight: 500;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-secondary);
    padding: var(--spacing-md) var(--spacing-sm);
    background-color: #f5f5f5 !important;  /* Light grey background */
    text-align: center;
    font-weight: 500;
    border-bottom: 1px solid var(--border-color);
    padding: var(--spacing-md) var(--spacing-sm);
}

/* Transport type headers */
.transport-header {
    text-align: center;
    font-size: 0.8rem;
    color: var(--text-secondary);
    padding: var(--spacing-xs) var(--spacing-sm) !important;
    background-color: var(--background-white);
    border-bottom: 1px solid var(--border-color);
    background-color: #f5f5f5 !important;  /* Light grey background */
    text-align: center;
    font-size: 0.8rem;
    color: var(--text-secondary);
    padding: var(--spacing-xs) var(--spacing-sm) !important;
    border-bottom: 1px solid var(--border-color);
}

/* Sort indicators */
.sort-indicator {
    margin-left: var(--spacing-xs);
    color: var(--text-secondary);
    opacity: 0.5;
    display: inline-block;
    vertical-align: middle;
}

.sort-indicator.active {
    opacity: 1;
    color: var(--primary-color);
}

/* Property basic info columns */
.property-info {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Score column styling */
.score-cell {
    font-weight: 600;
    text-align: center;
    width: 80px;
    color: var (--text-primary);  /* Use default text color */
}

/* Remove these color classes since we don't need them anymore */
.high-score,
.medium-score,
.low-score {
    color: var(--text-primary);  /* Override with default text color */
}

/* POI distance columns */
.poi-distance {
    text-align: center;
    font-size: 13px;
    color: var(--text-secondary);
}

/* POI header group */
.poi-header {
    background-color: var(--background-light) !important;
    text-align: center;
    font-weight: 500;
    border-bottom: 1px solid var(--border-color);
}

.poi-header small {
    display: block;
    font-size: 11px;
    color: var (--text-secondary);
    margin-top: 4px;
}

/* Transport type icons */
.transport-icon {
    margin-right: 4px;
    opacity: 0.7;
}

/* Sort indicators */
.sort-indicator {
    margin-left: var(--spacing-xs);
    color: var(--secondary-color);
}

/* Hover effects */
.rounded-table tbody tr:hover {
    background-color: var(--background-light);
    cursor: pointer;
}

/* Responsive layout adjustments */
@media (max-width: 1024px) {
    .top-container {
      flex-direction: column;
    }
  
    .slider-container,
    .list-map-container {
      width: 100%;
      margin: var(--spacing-sm) 0;
    }
  
    .list-map-container {
      flex-direction: column;
    }
  
    .dynamic-list,
    .map-container {
      width: 100%;
      height: 400px;
    }

    .property-info {
        max-width: 150px;
    }
    
    .poi-distance {
        font-size: 12px;
    }
  }

/* Updated unified header styles */
.basic-info-header,
.poi-header,
.transport-header {
  text-align: center;
  background-color: #f5f5f5 !important;
  color: var(--text-secondary) !important; /* Force text color */
  font-weight: 500;
  padding: var(--spacing-xs) var(--spacing-sm) !important;
  border-bottom: 1px solid var(--border-color);
}

/* Specific adjustments for POI headers */
.poi-header {
  padding: var(--spacing-sm) var(--spacing-sm) !important;
}

/* POI name and address */
.poi-name {
  font-weight: 500;
  margin-bottom: 2px;
}

.poi-address {
  font-size: 0.8rem;
}

/* Transport icon tweaks */
.transport-icon {
  margin-right: 4px;
  opacity: 0.7;
  font-size: 0.9rem;
}

/* Add entry button styling */
.add-entry-button {
    width: 100%;
    background-color: var(--background-white);
    color: var(--secondary-color);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--secondary-color);
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: var(--spacing-md);
}

.add-entry-button:hover {
    background-color: var(--secondary-color);
    color: var(--background-white);
}

/* Sort indicators */
.sort-indicator {
    margin-left: var(--spacing-xs);
    color: var(--secondary-color);
}

/* Hover effects */
.rounded-table tbody tr:hover {
    background-color: var(--background-light);
    cursor: pointer;
}

/* Customize scrollbar */
.dynamic-list::-webkit-scrollbar {
    width: 8px;
}

.dynamic-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.dynamic-list::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
    border-radius: 4px;
}

.dynamic-list::-webkit-scrollbar-thumb:hover {
    background: #2980b9;
}

.slider-header {
    position: sticky;
    top: 0;
    background-color: var(--background-white);
    z-index: 1;
    padding-bottom: var(--spacing-md);
    margin-bottom: var(--spacing-lg);
}

