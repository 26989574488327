/* Import variables */
@import './styles/variables.css';

/* General Reset */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  color: var(--text-primary);
}

.landing-page {
  display: flex;
  min-height: 100vh;
}

.landing-left {
  width: 70%;
  padding: var(--spacing-xl);
  background-color: var(--background-white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.homefinder-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 200px;
  font-weight: normal;
  color: black;
  margin-bottom: 10px; /* Reduce the space below the title */
}

.home-text {
  font-weight: bold; /* Make "Home" bold */
}

.landing-paragraph {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  color: #333;
  max-width: 500px;
  margin-top: 10px; /* Reduce the margin to bring the text closer */
}
.items-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 140px;
}

.item {
  width: 30%; /* Each item takes 1/3 of the width */
  text-align: left; /* Align content to the left */
  padding-left: 10px; /* Optional padding for better spacing */
}

.item-icon {
  display: block;
  font-size: 40px; /* Set the font size of the icon */
  margin: 0 auto; /* Centers the icon horizontally */
  margin-bottom: 5px; /* Reduced space between the icon and the title */
  color: #333; /* Optional: change icon color */
}

.item h3 {
  font-size: 24px;
  font-weight: bold;
}

.item p {
  font-size: 16px;
  color: #777;
  margin-top: 10px;
}

/* Right Section Styling */
.landing-right {
  width: 30%;
  background-color: var(--background-light);
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h2 {
  margin-bottom: 2rem;
}

/* Sign-In Button Styling */
.signin-button {
  background-color: var(--background-white);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  transition: all 0.3s;
}

.signin-button:hover {
  background-color: var(--background-light);
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
}

/* Icon Styling for Buttons */
.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Reset Password Link */
.reset-link {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
}

.reset-link:hover {
  text-decoration: underline;
}

/* Responsive layout */
@media (max-width: 768px) {
  .landing-page {
    flex-direction: column;
  }

  .landing-left,
  .landing-right {
    width: 100%;
  }

  .homefinder-title {
    font-size: 48px;
  }

  .items-bar {
    flex-direction: column;
    gap: var(--spacing-lg);
  }

  .item {
    width: 100%;
  }
}
