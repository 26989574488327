:root {
  /* Color Palette */
  --primary-color: #2c3e50;
  --secondary-color: #3498db;
  --accent-color: #e74c3c;
  --background-light: #f8f9fa;
  --background-white: #ffffff;
  --text-primary: #2c3e50;
  --text-secondary: #7f8c8d;
  --border-color: #e0e0e0;
  --success-color: #2ecc71;
  --warning-color: #f1c40f;
  --error-color: #e74c3c;

  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;

  /* Box Shadow */
  --shadow-sm: 0 2px 4px rgba(0,0,0,0.1);
  --shadow-md: 0 4px 8px rgba(0,0,0,0.1);
  --shadow-lg: 0 8px 16px rgba(0,0,0,0.1);
}

/* Media Query Breakpoints */
@media (max-width: 768px) {
  :root {
    --spacing-md: 12px;
    --spacing-lg: 20px;
    --spacing-xl: 28px;
  }
}
